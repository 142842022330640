import React, { useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { StyledEngineProvider } from '@mui/material/styles';
import './dialog.css';
import { Typography } from '@mui/material';

export default function AlertDialog({experience}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = ({data}) => {
    setOpen(false);
  };

  return (
    <div className='dialog-container'>
      <StyledEngineProvider injectFirst>
        <Button variant="outlined" onClick={handleClickOpen} className='dialog-btn'>
          See more
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
              style: {
                backgroundColor: '#282c34',
                boxShadow: "none"
              },
            }}
        >
          <DialogContent>
            <DialogContentText className='designation'>
              {experience.designation}
            </DialogContentText>
            <DialogContentText className='company'>
              {experience.company}
            </DialogContentText>
            <DialogContentText className='duration'>
              {experience.duration}
            </DialogContentText>
            <DialogContentText className='roles'>
              Roles & Responsibilities:
              {experience.responsibilities.map(role => 
                  <Typography className='responsibilities'>
                    {role}
                  </Typography>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus className='dialog-btn'>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </StyledEngineProvider>
    </div>
  );
}