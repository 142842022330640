const arr = [
    {
        title: 'Nomadic Fool',
        stack: 'Full-Stack Website',
        desc: 'ReactJS & NodeJS based Full-Stack Travel blog website that provides a platform to share the places travelled so far.',
        github: 'https://github.com/ud17/blog_website_frontend',
        keywords: [
                'NodeJS',
                'ReactJS',
                'AWS',
                'AWS EC2',
                'AWS CodePipeline',
                'AWS CodeBuild',
                'AWS CodeDeploy',
                'AWS SNS',
                'Docker',
                'S3 Bucket',
                'Bash/shell script',
                'RESTful APIs',
                'ExpressJS',
                'MongoDB',
                'React Redux Toolkit',
                'JavaScript',
                'HTML',
                'CSS',
                'Postman',
                'Mongodb Compass',
                'Git',
                'Postman'
        ]
    },
    {
        title: 'Recipe',
        stack: 'Full-Stack Website',
        desc: 'ReactJS & NodeJS based Full-Stack website where in a user can browse & learn a variety of cuisines from different parts of the world.',
        github: 'https://github.com/ud17/recipe_frontend',
        keywords:[
            'NodeJS',
            'ExpressJS',
            'ReactJS',
            'Spring Boot',
            'MongoDB',
            'React Redux Toolkit',
            'RESTful APIs',
            'JavaScript',
            'HTML',
            'CSS',
            'Postman',
            'Mongodb Compass',
            'Git',
            'Postman'
        ]
    },
    {
        title: 'Docker App',
        stack: 'NodeJS Application',
        desc: 'NodeJS based backend application containerized using Docker to run across multiple platforms.',
        github: 'https://github.com/ud17/docker_app',
        keywords:[
            'NodeJS',
            'ExpressJS',
            'Docker',
            'AWS ECR',
            'Linux',
            'OS',
            'MongoDB',
            'RESTful APIs',
            'YAML',
            'JavaScript',
            'HTML',
            'CSS',
            'Mongodb Compass',
            'Git',
            'Postman'
        ]
    },
    {
        title: 'Cryptoverse',
        stack: 'ReactJS Website',
        desc: 'Devised ReactJS based Application that consumes data from Rapid API to display global cryptocurrency trends.',
        github: 'https://github.com/ud17/cryptoverse',
        hosting: 'https://cryptoverse-udit.netlify.app/',
        keywords:[
            'ReactJS',
            'Netlify',
            'Cloud',
            'RESTful APIs',
            'React Redux Toolkit',
            'ReactJS Charts',
            'Ant Design',
            'JavaScript',
            'HTML',
            'CSS',
            'Git',
        ]
    },
    {
        title: 'LeetCode',
        stack: 'DS & Algorithms',
        desc: 'Repository with solutions for various data structures along with the problem itself.',
        github: 'https://github.com/ud17/Leetcode',
        leetcode: 'https://leetcode.com/ud17/',
        keywords: [
            'Java',
            'JavaScript',
            'Python',
            'Data Structures',
            'Algorithms',
            'Binary Search'
        ]
    },
    {
        title: 'Amazon Scraper Api',
        stack: 'NodeJS RESTful APIs',
        desc: 'Amazon scraper apis for searching relevant product details.',
        github: 'https://github.com/ud17/AmazonScraperAPI',
        keywords:[
            'NodeJS',
            'ExpressJS',
            'Vercel',
            'Cloud',
            'RESTful APIs'
        ]
    },
    {
        title: 'Spring Boot',
        stack: 'Java RESTful APIs',
        desc: 'REST APIs developed leveraging Spring Boot Framework.',
        github: 'https://github.com/ud17/spring_boot',
        keywords: [
            'Spring Boot',
            'Java',
            'RESTful APIs'
        ]
    }
];

module.exports = {
    getArray: function() {
      return arr;
    }
};