import React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Dialog from '../dialog/Dialog';
import { Typography } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import './aboutme.css';

const experience = require("../../utils/experience");
const education = require("../../utils/education");

function AboutMe() {
  return (
    <div className='edu-container font'>
        <StyledEngineProvider injectFirst>
            <Typography className='heading'>
                Experience
            </Typography>
            <Timeline position="alternate" className='timeline'>
                {experience.map(experience => {
                    return (
                        <TimelineItem>
                            <TimelineSeparator>
                            <TimelineDot className='dot'/>
                            <TimelineConnector className='connector'/>
                            </TimelineSeparator>
                            <TimelineContent>
                                <Typography className='bold'>{experience.designation}</Typography>
                                <Typography>{experience.company}</Typography>
                                <Dialog experience={experience} />
                            </TimelineContent>
                        </TimelineItem>
                    )
                })}
            </Timeline>
            <Typography className='heading'>
                Education
            </Typography>
            <Timeline position="alternate" className='timeline'>
                {education.map(education =>
                    <TimelineItem>
                        <TimelineSeparator>
                        <TimelineDot className='dot'/>
                        <TimelineConnector className='connector'/>
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography className='bold'>{education.program}</Typography>
                            <Typography>{education.college}</Typography>
                            <Typography>{education.duration}</Typography>
                            {education.scholarship && <Typography>{education.scholarship}</Typography>}
                        </TimelineContent>
                    </TimelineItem>
                )}
            </Timeline>
        </StyledEngineProvider>
    </div>
  )
}

export default AboutMe;